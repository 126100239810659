import React from "react"
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Project from "../components/projet-solidaire"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const doc = data.prismic.allMaison_ouvertes.edges.slice(0,1).pop();
  return (
  <Layout>
    <SEO title={RichText.asText(doc.node.titre_page)} />
    <Image title={RichText.asText(doc.node.titre_page)}
            text={RichText.render(doc.node.description_1)} 
            img={doc.node.image_fondsSharp.childImageSharp.fluid} />
    <section class="content-center main-content">
      {RichText.render(doc.node.description)}
    </section>
    <Project />
  </Layout>
  )
}

	
export const query = graphql`
{
  prismic {
    allMaison_ouvertes {
      edges {
        node {
          titre_page
          description_1
          description
          image_fonds
          image_fondsSharp {
            childImageSharp {
              fluid(maxWidth: 3850) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`

export default IndexPage
